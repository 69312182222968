@import '/node_modules/animate.css/animate.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.banner {
    height: 100vh;
    background-image: url("./images/banner.png");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.menu a {
    @apply border-red-600 cursor-pointer px-4 py-1 text-sm
}

.menu a.active {
    @apply border-b
}

.menu a svg {
    @apply w-4 h-4 mr-3;
}


.wave {
    border-radius: 1000% 1000% 0 0;
    position: absolute;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    @apply bottom-0 left-0 opacity-90 bg-white bg-opacity-40;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.7;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.8
}

@keyframes wave {
    2% {
        transform: translateX(1%);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1%);
    }
}

.animated-gradient {
    @apply bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500;
    background-size: 400%;
    animation: gradient-animate 10s ease-in-out infinite;
}

@keyframes gradient-animate {
    0%,
    100% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
}

.smooth {
    @apply transition-all ease-in-out duration-500;
}
